import qs from "qs";
import * as auth from "auth-provider";
import { useAuth } from "context/auth-context";
import { useCallback } from "react";
import { message, Spin } from "antd";
import ReactDOM from "react-dom";
import { HttpFullLoading } from "component/lib";
import { resetRoutes } from "utils";
const apiUrl = process.env.REACT_APP_API_URL;

//请求的配置类型,有额外需要配置的参数因此必须继承RequestInit
interface config extends RequestInit {
  data?: object;
  token?: string | null;
  responseType?: boolean;
  loading?: boolean;
}

/**
 *打开全局loading
 */
export const showLoading = () => {
  const dom = document.createElement("div");
  dom.setAttribute("id", "izhaoren-http-loading");
  document.body.appendChild(dom);
  ReactDOM.render(HttpFullLoading(), dom);
};

/**
 *关闭全局loading
 */
export const hideLoaing = () => {
  const dom = document.getElementById("izhaoren-http-loading");
  if (dom) {
    document.body.removeChild(dom);
  }
};

//fetch只会在网络断开等情况下抛异常，状态码不为2xx也是对的
export const http = async (
  endpoint: string, //请求的路径
  { data, token, headers, responseType, loading, ...customConfig }: config = {} //请求的配置参数
) => {
  const config = {
    method: "GET", //默认发请求的方式，会被之后的customConfig所覆盖
    headers: {
      Authorization: token ? `Bearer ${token}` : "", //如果有token，就发token
      "Content-Type": data ? "application/json" : "", //配置请求体的方式
      //  responseType:  responseType?  'blob':'',
    },
    ...customConfig,
  };
  if (config.method.toUpperCase() === "GET") {
    endpoint += `?${qs.stringify(data, { arrayFormat: "indices" })}`; //GET请求传参的方式
  } else {
    config.body = JSON.stringify(data); //POST/DELETE/PATCH传参的方式
  }
  //当需要打开全局Loading时，就打开
  if (loading) {
    showLoading();
  }
  return window
    .fetch(`${apiUrl}/${endpoint}`, config)
    .then(async (response: Response) => {
      //当需要关闭全局Loading时，就关闭
      if (loading) {
        hideLoaing();
      }
      //当检测到没有token时，就提示重新登陆
      if (response.status === 401) {
        message.info("登陆过期！请重新登录").then(async () => {
          await auth.logout();
          window.location.reload();
          resetRoutes();
          return Promise.reject({ message: "请重新登录" });
        });
      }

      const data = await response.json();

      if (
        data &&
        data.resp_code === 1 &&
        data.resp_msg &&
        data.resp_msg.indexOf("Invalid access token") > -1
      ) {
        await auth.logout();
        window.location.reload();
        resetRoutes();
        return Promise.reject({ message: "请重新登录" });
      }

      if (response.ok) {
        return data;
      } else {
        //请求失败返回Promise
        //接口报错直接提示报错原因
        message.error(data.resp_msg);
        return Promise.reject(data);
      }
    });
};

//useHttp每次就不需要重新传入token了
export const useHttp = () => {
  const { token } = useAuth();
  return useCallback(
    (...[endpoint, config]: Parameters<typeof http>) =>
      http(endpoint, { token: token, ...config }),
    [token]
  );
};
