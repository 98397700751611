import { Avatar, Image } from "antd";
import { getAvatarUrl } from "utils";
import DefaultAvatarImage from "assets/img/AccountInfoAvatar.png";
import DefaultAvatarSamll from "assets/img/defaultAvatarSmall.png";
import styled from "@emotion/styled";
type AvatartProps = React.ComponentProps<typeof Avatar>;
interface DefaultAvatarProps extends AvatartProps {
  image: string;
  fontSize?: number;
  noicon?: boolean;
}

export const DefaultAvatar = ({
  image,
  size,
  fontSize = 1.5,
  noicon,
  ...props
}: DefaultAvatarProps) => {
  return (
    <AvatarWrapper
      src={
        image ? (
          <Image src={getAvatarUrl(image)} />
        ) : size === "small" || Number(size) <= 30 ? (
          <img src={DefaultAvatarSamll} alt="" />
        ) : (
          <img src={DefaultAvatarImage} alt="" />
        )
      }
      fontSize={fontSize}
      alt="头像"
      size={size}
      noicon={noicon}
      {...props}
    />
  );
};

const AvatarWrapper = styled(Avatar)<{
  fontSize: number;
  noicon?: boolean;
}>`
  .ant-image-mask {
    font-size: ${(props) => props.fontSize + "rem"};
  }
  .anticon-eye {
    display: ${(props) => (props.noicon ? "none" : "inline")};
  }
`;
