import { useCallback, useReducer, useState } from "react";
import { useMountedRef } from "utils";
//定义组件中的Loading状态和错误显示
interface State<D> {
  error: errorType | null;
  data: D | null;
  status: "idle" | "loading" | "error" | "success";
  count?: number;
}

export interface errorType extends Error {
  resp_msg: string;
}

const defaultState: State<null> = {
  error: null,
  data: null,
  status: "idle",
  count: 0,
};

const defaultConfig = {
  throwOnError: false,
};

const useSafeDispatch = <T>(dispatch: (...args: T[]) => void) => {
  const mountedRef = useMountedRef();
  return useCallback(
    (...args: T[]) => (mountedRef.current ? dispatch(...args) : void 0),
    [dispatch, mountedRef]
  );
};

export const useAsync = <D>(
  initialState?: State<D>,
  initialConfig?: typeof defaultConfig
) => {
  const [state, dispatch] = useReducer(
    (state: State<D>, action: Partial<State<D>>) => ({ ...state, ...action }),
    {
      ...defaultState,
      ...initialState,
    }
  );

  const config = { ...defaultConfig, ...initialConfig };

  const safeDispatch = useSafeDispatch(dispatch);

  const [refresh, setRefresh] = useState(() => () => {});

  const setData = useCallback(
    (data: D, count?: number) => {
      safeDispatch({
        data,
        error: null,
        status: "success",
        count: count ? count : 0,
      });
    },
    [safeDispatch]
  );

  const setError = useCallback(
    (error: errorType) => {
      safeDispatch({
        data: null,
        error: error,
        status: "error",
        count: 0,
      });
    },
    [safeDispatch]
  );

  const run = useCallback(
    (promise: Promise<D>, runConfig?: { refresh: () => Promise<D> }) => {
      if (!promise || !promise.then) {
        throw Error("请传入Promise类型数据");
      }

      setRefresh(() => () => {
        if (runConfig?.refresh) run(runConfig?.refresh(), runConfig);
      });

      safeDispatch({ status: "loading" });

      return promise
        .then((data: any) => {
          data.count ? setData(data.data, data.count) : setData(data.data);
          return data;
        })
        .catch((error) => {
          setError(error);
          if (config.throwOnError) {
            return Promise.reject(error);
          }
          return error;
        });
    },
    [config.throwOnError, safeDispatch, setData, setError]
  );

  return {
    isIdle: state.status === "idle",
    isLoading: state.status === "loading",
    isError: state.status === "error",
    isSuccess: state.status === "success",
    run,
    refresh,
    setError,
    setData,
    ...state,
  };
};
