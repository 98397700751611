import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import DefaultAvatarSamll from "assets/img/defaultAvatarSmall.png";
import background from "assets/img/background.png";
import { FullPageLoading, Rows } from "component/lib";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import trapezoid from "assets/img/trapezoid.png";
import line from "assets/img/line.png";
import UserOptions from "component/userOptions";
import { ReactComponent as UserIcon } from "assets/img/user_manageent.svg";
import { ReactComponent as WorkIcon } from "assets/img/work_management.svg";
import { ReactComponent as SettlementIcon } from "assets/img/settlement_management.svg";
import { ReactComponent as CompanyIcon } from "assets/img/company_management.svg";
import { ReactComponent as RoleIcon } from "assets/img/role_management.svg";
import { ReactComponent as ComplaintsIcon } from "assets/img/complaints_management.svg";
import { ReactComponent as EvaluationIcon } from "assets/img/evaluation_management.svg";
import { ReactComponent as NoticeIcon } from "assets/img/notice.svg";
import Logo from "assets/img/logo.svg";
import { deletLocalParams, getAvatarUrl, resetRoutes } from "utils";
import { useAuth } from "context/auth-context";
import { Avatar as AntdAvatar } from "antd";
import { DefaultAvatar } from "component/DefaultAvatar/DefaultAvatar";

export default function ProjectList() {
  const { user } = useAuth();

  const [isStretch, setIsStretch] = useState(true);

  const [routerConfig, setRouterConfig] = useState<
    { path: string; element: JSX.Element }[]
  >([]);

  const getMoutchRoute = (url: string) => {
    const Component = React.lazy(
      () => import(`screens/project-list/pages${url}`)
    );
    return <Component></Component>;
  };

  const { authority } = useAuth();

  useEffect(() => {
    //获取项目路由配置
    if (authority) {
      const routerConfig = authority.reduce<
        { path: string; element: JSX.Element }[]
      >(function (total, cur) {
        total.push({ path: cur.url, element: getMoutchRoute(cur.path) });
        return total;
      }, []);
      setRouterConfig([...routerConfig]);
    }
  }, [authority]);

  return (
    <Router>
      <Container>
        <Content>
          <MenuAreaWrapper {...{ isStretch, setIsStretch }}></MenuAreaWrapper>
          <MainArea isStretch={isStretch}>
            <MainContainer>
              <PageHeader
                account={user?.account || ""}
                avatar={user?.avatar || ""}
              />
              <MainContent>
                <React.Suspense fallback={<FullPageLoading></FullPageLoading>}>
                  <Routes>
                    {routerConfig.map((item) => {
                      return (
                        <Route
                          path={item.path}
                          element={item.element}
                          key={item.path}
                        ></Route>
                      );
                    })}
                    <Navigate to="/account"></Navigate>
                  </Routes>
                </React.Suspense>
              </MainContent>
            </MainContainer>
          </MainArea>
        </Content>
      </Container>
    </Router>
  );
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(${background});
  background-size: calc(100vw), calc(100vh);
  padding: 4rem 4rem;
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  position: relative;
  flex: auto;
`;

const MenuArea = styled.div`
  justify-content: center;
  position: sticky;
  flex: none;
  top: 4rem;
  margin-right: 5rem;
`;

const MainArea = styled.div<{ isStretch: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background: #ffffff;
  box-shadow: 0 0.4rem 0.8rem rgba(138, 151, 182, 0.4);
  border-radius: 4rem;
  padding: 3.2rem 5.3rem 2.5rem 4.8rem;
  box-sizing: border-box;
  width: calc(100% - ${({ isStretch }) => (isStretch ? "32.6rem" : "44.6rem")});
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled(Rows)``;

const HeaderLeft = styled(Rows)``;

const HeaderRight = styled(Rows)``;

const MainContent = styled.div`
  flex: 1;
`;

const PageHeader = ({
  account,
  avatar,
}: {
  account: string;
  avatar: string;
}) => {
  return (
    <Header between={true}>
      <HeaderLeft gap={2.6}>
        <div onClick={resetRoutes} style={{ cursor: "pointer" }}>
          <img src={Logo} alt="logo" />
        </div>
        <HeaderTitle onClick={resetRoutes}>i找人后台系统</HeaderTitle>
      </HeaderLeft>
      <HeaderRight gap={true}>
        {/* <NoticeIcon style={{ cursor: "pointer" }}></NoticeIcon> */}
        <DefaultAvatar image={avatar} fontSize={0.1}></DefaultAvatar>
        <div>{account}</div>
        <UserOptions />
      </HeaderRight>
    </Header>
  );
};

interface IMenuAreaWrapperProps {
  isStretch: boolean;
  setIsStretch: React.Dispatch<React.SetStateAction<boolean>>;
}

interface NavListProps {
  id: number;
  path: string;
  activecolor: number;
  navName: string;
  img: JSX.Element;
}

const MenuAreaWrapper = ({
  isStretch,
  setIsStretch,
}: IMenuAreaWrapperProps) => {
  const { pathname } = useLocation();

  //获取左侧导航栏图标
  const getImgIcon = (navName: string) => {
    switch (navName) {
      case "账号管理":
        return <UserIcon></UserIcon>;
      case "任务管理":
        return <WorkIcon></WorkIcon>;
      case "费用管理":
        return <SettlementIcon></SettlementIcon>;
      case "企业管理":
        return <CompanyIcon></CompanyIcon>;
      case "角色管理":
        return <RoleIcon></RoleIcon>;
      case "投诉管理":
        return <ComplaintsIcon></ComplaintsIcon>;
      case "短信管理":
        return <EvaluationIcon></EvaluationIcon>;
      default:
        return <UserIcon></UserIcon>;
    }
  };

  //定义菜单列表
  const [navList, setNavList] = useState<NavListProps[]>([]);

  //获取当前用户权限菜单
  const { authority } = useAuth();

  //刷新后根据url保持颜色
  useEffect(() => {
    if (authority) {
      const navList = authority.reduce<NavListProps[]>((pre, cur) => {
        if (cur.parentId === -1) {
          pre.push({
            id: cur.id,
            path: cur.url,
            activecolor: pathname.includes(cur.url) ? 1 : 0,
            navName: cur.name,
            img: getImgIcon(cur.name),
          });
        }
        return pre;
      }, []);
      setNavList(navList);
    }
  }, [authority, pathname]);

  const history = useNavigate();

  //切换路由
  const goToMenu = (item: NavListProps) => {
    history(`${item.path}`);
    deletLocalParams();
    setNavList(
      [...navList].map((value) => ({
        ...value,
        activecolor: value.id === item.id ? 1 : 0,
      }))
    );
  };
  return (
    <MenuArea>
      {/* 伸缩布局，用isStretch状态决定显示什么伸缩还是未伸缩 */}
      <NavigationContainer>
        <div className={"navigation active"}>
          <ul>
            {navList.map((item) => (
              <li onClick={() => goToMenu(item)} key={item.id}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  style={
                    item.activecolor
                      ? {
                          background: "#ffe8db",
                          borderRight: "4px solid #EC803E",
                        }
                      : undefined
                  }
                >
                  <span className={"icon"}>{item.img}</span>

                  <span className={"title"}>{item.navName}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div
          className={"toggle"}
          onClick={() => {
            const navigation = document.querySelector(".navigation");
            navigation?.classList.toggle("active");
            setIsStretch(!isStretch);
          }}
        >
          {isStretch ? (
            <img src={line} alt="line" />
          ) : (
            <img src={trapezoid} alt="trapezoid" />
          )}
        </div>
      </NavigationContainer>
    </MenuArea>
  );
};

const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 2.8rem;
  line-height: 3.9rem;
  color: #4076f5;
  cursor: pointer;
`;

const NavigationContainer = styled.div`
  position: relative;
  .navigation {
    position: relative;
    width: 8rem;
    height: 58.5rem;
    background: #fff;
    border-radius: 5.8rem;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
    padding: 4.8rem 0;
    * {
      margin: 0;
      padding: 0;
    }
    li {
      position: relative;
      list-style: none;
      width: 100%;
      /* transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
      margin-bottom: 1rem;
    }

    li:hover {
      background: #efefef;
    }

    ul {
      position: absolute;
      top: 10;
      left: 0;
      width: 100%;
    }

    a {
      position: relative;
      display: block;
      width: 100%;
      display: flex;
      text-decoration: none;
      color: #4076f5;
      font-weight: bold;
      /* margin-bottom: 2rem; */
    }

    .icon {
      position: relative;
      display: block;
      min-width: 8rem;
      height: 5.6rem;
      line-height: 5.6rem;
      text-align: center;
      font-size: 2.4rem;
    }

    .title {
      position: relative;
      display: block;
      height: 5.6rem;
      line-height: 5.6rem;
      white-space: nowrap;
    }
  }
  .navigation.active {
    width: 18rem;
  }
  .toggle {
    position: absolute;
    width: 1.6rem;
    height: 6.4rem;
    background: transparent;
    top: calc(50% - 90px);
    right: -1.6rem;
    img {
      -webkit-filter: drop-shadow(
        0.1rem 0.1rem 0.1rem rgba(138, 151, 182, 0.4)
      );
      filter: drop-shadow(0.1rem 0.1rem 0.1rem rgba(138, 151, 182, 0.4));
      width: 100%;
    }
    cursor: pointer;
  }
`;
