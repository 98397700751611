import { useAuth } from "context/auth-context";
import { Form, Typography } from "antd";
import { AuthForm } from "auth-provider";
import { LongButton } from "assets/style/LongButton";
import { useAsync } from "utils/useAsync";
import { Input } from "antd";
import styled from "@emotion/styled";
interface LoginScreenProps {
  error: Error | null;
  onError: (error: Error) => void;
}

export default function LoginScreen({ error, onError }: LoginScreenProps) {
  const { login } = useAuth();

  const { run, isLoading } = useAsync(undefined, { throwOnError: true });

  const handleSubmit = async (values: AuthForm) => {
    const params = { ...values, grant_type: "password_code" };
    try {
      await run(login(params));
    } catch (error) {
      onError(error.error_description);
    }
  };
  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        name="username"
        rules={[{ required: true, message: "请输入用户名" }]}
      >
        <ShowInput type="text" prefix={"账号"} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "请输入密码" }]}
      >
        <ShowPswInput type="password" prefix={"密码"} />
      </Form.Item>
      {error ? <Typography.Text type="danger">{error}</Typography.Text> : null}
      <Form.Item>
        <LongButton
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{ marginTop: 64, height: 40 }}
        >
          登录
        </LongButton>
      </Form.Item>
    </Form>
  );
}
const ShowInput = styled(Input)`
  background: #f9faff !important;
  border: 0.1rem solid transparent !important;
  box-sizing: border-box;
  border-radius: 0.4rem;
  .ant-input {
    background: #f9faff !important;
    text-indent: 0.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #333333;
  }
  .ant-input-prefix {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #4f4f4f;
  }
`;
const ShowPswInput = styled(Input.Password)`
  background: #f9faff !important;
  border: 0.1rem solid transparent !important;
  box-sizing: border-box;
  border-radius: 0.4rem;
  .ant-input {
    background: #f9faff !important;
    text-indent: 0.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #333333;
  }
  .ant-input-prefix {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #4f4f4f;
  }
`;
