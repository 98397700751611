import styled from "@emotion/styled";
import { Spin, Typography } from "antd";
/**
 * 自定义Row组件 ：我看了UI很多都是一行当中左右拉开垂直居中的，因此封装了一个样式组件
 */
export const Rows = styled.div<{
  gap?: number | boolean; //元素之间的margin-right
  between?: number | boolean; //设置是否在水平方向左右拉开
  marginBottom?: number; //组件的margin-bottom
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.between ? "space-between" : undefined)};
  margin-bottom: ${(props) => props.marginBottom + "rem"};
  > * {
    margin-top: 0px !important;
    margin-right: ${(props) =>
      typeof props.gap === "number"
        ? props.gap + "rem"
        : props.gap
        ? "2rem"
        : undefined};
  }
`;

/**
 * 定义错误边界和全局Loaing加载
 */

const FullPage = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 *定义发送请求的时候全局loading
 *
 */

const HttpFullPage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
`;

//全屏loading
export const FullPageLoading = () => (
  <FullPage>
    <Spin size={"large"} />
  </FullPage>
);

//全局显示错误信息
export const FullPageErrorFallback = ({ error }: { error: Error | null }) => (
  <FullPage>
    <Typography.Text type="danger">{error?.message}</Typography.Text>
  </FullPage>
);

export const HttpFullLoading = () => (
  <HttpFullPage>
    <Spin size={"large"} />
  </HttpFullPage>
);
