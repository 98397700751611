import { message } from "antd";
import { tabsStorageKey } from "component/Tabs";
import qs from "qs";
import { accountProjects } from "screens/project-list/pages/account/list";

export const localStorageKey = "__auth_provider_token__";
const apiUrl = process.env.REACT_APP_API_URL;

//定义用户字段
export interface Users extends accountProjects {
  id: number;
  access_token: string;
  account: string;
  enterpriseId: number;
  roles: {
    code: string;
    createTime: string;
    id: number;
    name: string;
    remark: string;
    roleType: number;
    status: number;
    updateTime: string;
    userId: number;
  }[];
  permissions: {
    functionType: number;
    name: string;
    path: string;
    url: string;
  }[];
}

//定义表单提交字段
export interface AuthForm {
  username: string;
  password: string;
}

//获取当前的token
export const getToken = () => window.localStorage.getItem(localStorageKey);

//将服务端返回的User中的token保存在本地
export const handleUserResponse = ({ data }: { data: Users }) => {
  window.localStorage.setItem(localStorageKey, data.access_token || "");
  return data;
};

//用户登录
export const login = (params: AuthForm) => {
  return fetch(`${apiUrl}/api-uaa/oauth/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      Authorization: "Basic d2ViQXBwOndlYkFwcA==",
    },
    body: qs.stringify(params),
  })
    .then(async (response) => {
      if (response.ok) {
        return handleUserResponse(await response.json());
      } else {
        return Promise.reject(await response.json());
      }
    })
    .catch((error) => message.error(error.resp_msg));
};

//用户退出
export const logout = async () => {
  window.localStorage.clear();
};
