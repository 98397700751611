import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  CURRENT_LIMIT,
  CURRENT_PAGE,
  CURRENT_PARAMS,
} from "component/List/Pagination";
import { useAuth } from "context/auth-context";
import { useEffect, useRef, useState } from "react";
import { authorityType, taskStatusList } from "./type";

//清除数组里的空值
export const cleanArray = (target: any[]) => {
  if (Array.isArray(target)) {
    const res = target.reduce((pre, cur) => {
      if (!isVoid(cur)) {
        pre.push(cur);
      }
      return pre;
    }, []);
    return res;
  } else {
    return [];
  }
};

//判断空值
export const isVoid = (value: unknown) => {
  const res =
    value === undefined ||
    value === null ||
    value === "" ||
    (value instanceof Array && cleanArray(value).length === 0);

  return res;
};

//清除对象中的空值
export const cleanObject = (object: { [key: string]: unknown }) => {
  const result = { ...object }; //深度克隆，不改变元对象
  Object.keys(result).forEach((key) => {
    //删除对象值为false的值
    if (key !== "tags" && isVoid(result[key])) {
      delete result[key];
    }
  });
  return result;
};

//模拟useEffect
export const useMount = (callBack: () => void) => {
  useEffect(() => {
    callBack();
  }, []);
};

//自定义表头信息过滤
export const filterArray = (
  targetArray: { title: string; [propName: string]: any }[],
  selectArray: string[]
) => {
  const res = targetArray.filter((n) => selectArray.indexOf(n.title) !== -1);
  return res;
};

//重置路由
export const resetRoutes = () =>
  (window.location.href = window.location.origin);

//图片预览
export const getBase64 = (file: any): Promise<FileReader> => {
  return new Promise<FileReader>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader);
    reader.onerror = (error) => reject(error);
  });
};

//改变当前页面的标题
export const useTitle = (title: string, keepUnMount = true) => {
  const oldTitle = document.title;
  useEffect(() => {
    document.title = title;
  }, [title]);

  useMount(() => {
    if (!keepUnMount) {
      return () => {
        document.title = oldTitle;
      };
    }
  });
};

//判断自定义表头字段是否选中
export const isCheckedOrDisabled = (
  target: string,
  noModifiedArray: string[],
  defaultSelectArray: string[],
  action?: string
) => {
  let flag: string[] | undefined;
  action === "checked" ? (flag = defaultSelectArray) : (flag = noModifiedArray);
  return flag?.indexOf(target) !== -1;
};

// 邮件验证
export const emailCheck = (email: string) => {
  if (email === "" || !email) {
    return false;
  }
  email = email.trim();
  const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
  return reg.test(email);
};

// 电话号码验证
export const phoneCheck = (phone: string) => {
  if (phone === "" || !phone) {
    return false;
  }
  phone = phone.trim();
  const reg = /^134[0-8]\d{7}$|^13[^4]\d{8}$|^14[5-9]\d{8}$|^15[^4]\d{8}$|^16[6]\d{8}$|^17[0-8]\d{8}$|^18[\d]{9}$|^19[8,9]\d{8}$/;
  return reg.test(phone);
};

// 自定义表头字段解析
export const generateCustomTableTitle = (config: SelectColumns[]) => {
  const allTitles = [],
    checkedTitles = [],
    disabledTitles = [];
  for (let i = 0, l = config.length; i < l; i++) {
    allTitles.push(config[i]);
    config[i].checked && checkedTitles.push(config[i].name);
    config[i].disable && disabledTitles.push(config[i].name);
  }
  return { allTitles, checkedTitles, disabledTitles };
};

//搜索debounce
export const useDebounce = <V>(value: V, delay?: number) => {
  const [debounceValue, setDebounceValue] = useState(value);
  useEffect(() => {
    const timeout = setTimeout(() => setDebounceValue(value), delay);
    return () => clearTimeout(timeout);
  }, [value, delay]);

  return debounceValue;
};

// 获取随机颜色
export const randomColor = (type: "antd" | "custom", key?: number) => {
  let r = Math.ceil(Math.random() * 255),
    g = Math.ceil(Math.random() * 255),
    b = Math.ceil(Math.random() * 255);
  let antd = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];
  switch (type) {
    case "antd":
      key =
        key === undefined
          ? Math.ceil(Math.random() * antd.length)
          : Math.abs(key % 11);
      return antd[key];
    case "custom":
      return `rgb(${r},${g},${b})`;
    default:
      break;
  }
};
// 获取固定颜色
export const constColor = (id: number) => {
  let color = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];
  let key = Math.floor(id % color.length);
  return color[key];
};

/**
 * 为解决在组件卸载时赋值报错
 * 返回组件的挂载状态，没挂载或者卸载返回false 挂载返回ture
 */
export const useMountedRef = () => {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });
  return mountedRef;
};

// 获取type中的name
interface UtilType {
  id: number;
  name: string;
}
export const nameFromType: (types: UtilType[]) => Array<string> = (types) => {
  let emptyArr = Array<string>(types.length);
  return types.reduce((prev, next) => {
    prev[next.id] = next.name;
    return prev;
  }, emptyArr);
};
//  初始化已选列
/**
 * @param selected 列title或是列dataIndex
 * @param columns 所有列
 */
export const initColumns = (selected: string[], columns: any) => {
  return columns.filter((item: any) => {
    if (
      selected.indexOf(item.title) > -1 ||
      selected.indexOf(item.dataIndex) > -1
    ) {
      return item;
    }
  });
};
/**
 * #TODO 支持小数点？
 * 将金额转为中文大写  姑且不支持小数点
 * 例：456544 => 肆拾伍万陆仟伍佰肆拾肆
 *    300 => 叁佰
 * */
export const toUpperCaseChinese = (number: number) => {
  const digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"],
    baseBit = ["拾", "佰", "仟"],
    bit = ["", "万", "亿", "兆"];
  let str = String(number);
  let strGroup = [];
  // 数字分组 四位数一组
  for (let i = str.length; i > 0; i = i - 4) {
    // 将数字每位提取出来并合成数组
    let nodeStr = str.substring(i - 4, i);
    let strNumber = nodeStr.replace(/\d/gi, "$&,").replace(/,$/, "").split(",");
    strGroup.push(strNumber);
  }
  let upperChinese = "";
  for (let i = 0; i < strGroup.length; i++) {
    upperChinese = bit[i] + upperChinese;
    let group = strGroup[i];
    for (let j = group.length - 1, i = -1; j > -1; j--, i++) {
      let digitIndex = Number(group[j]);
      if (digitIndex !== 0) {
        if (j != group.length - 1 && i != -1) {
          upperChinese = digit[digitIndex] + baseBit[i] + upperChinese;
        } else {
          upperChinese = digit[digitIndex] + upperChinese;
        }
      }
    }
  }
  return upperChinese;
};
//获取权限过滤之后的数据
export const getAuthorityList = (
  authority: authorityType[] | undefined,
  filterList: {
    type: number;
    ancestorsId: number;
    id?: number[];
  }[]
) => {
  let authorityList: authorityType[] = [];

  if (authority) {
    authorityList = JSON.parse(JSON.stringify(authority));
  }

  const res = authorityList?.filter((item) =>
    filterList.find((value) => value.ancestorsId === item.id)
  );

  const temp = [] as any;

  filterList.filter((item) => {
    const result = res?.find((ancestor) => ancestor.id === item.ancestorsId);

    const data = result?.subMenus.filter((boundary) =>
      item.id?.find((text) => text === boundary.id)
    );

    if (result && data) {
      result.subMenus = data;
    }
    temp.push(result);
  });

  if (temp.length > 0) {
    return temp;
  }
  return res;
};

// 判断权限是否需要展示
export const useAuthButton = () => {
  const { user } = useAuth();

  const hideAuth = (code: string | undefined) => {
    if (!code) {
      return false;
    }
    const res = user?.permissions?.find((item) => item.path === code)
      ? false
      : true;
    return res;
  };
  return { hideAuth };
};

/**
 * 传入一个对象，和键集合，返回对应的对象中的键值对
 * @param obj
 * @param keys
 */
export const subset = <
  O extends { [key in string]: unknown },
  K extends keyof O
>(
  obj: O,
  keys: K[]
) => {
  const filteredEntries = Object.entries(obj).filter(([key]) =>
    keys.includes(key as K)
  );
  return Object.fromEntries(filteredEntries) as Pick<O, K>;
};

/**
 * 为图片添加域名地址
 */
export const getImageUrl = (file: string): string => {
  if (typeof file !== "string") {
    return file;
  }

  return file.startsWith("http") ||
    file.startsWith("/api") ||
    file.startsWith("data:")
    ? file
    : process.env.REACT_APP_API_URL + file;
};

/**
 * 设置导出excel地址
 * @param file
 * @returns
 */

export const getExportExcelUrl = (file: string) => {
  return file.startsWith("http") ||
    file.startsWith("/api") ||
    file.startsWith("data:")
    ? file
    : process.env.REACT_APP_API_URL + "/file/" + file;
};

export const getAvatarUrl = (file: string): string => {
  return getImageUrl(file);
};

/**
 * 两个数组去重
 * @param list1
 * @param list2
 * @returns
 */

export const onlyArray = (list1: any[], list2: any[]) => {
  const idSet = list1.reduce((acc, v) => {
    acc[v.id] = true;
    return acc;
  }, {});
  // 遍历list2，去掉在idSet中存在的id
  const result = list2.filter((v) => !idSet[v.id]);
  return result;
};

//判断当前性别
export const genderInfo = (value: any) => {
  if (value === null) {
    return "";
  }

  if (Number(value) === 0) {
    return "男";
  }

  if (Number(value) === 1) {
    return "女";
  }
};

export const educationInfo = (value: any) => {
  if (!value) {
    return "";
  }
  switch (Number(value)) {
    case 1:
      return "高中";
    case 2:
      return "专科";
    case 3:
      return "本科";
    case 4:
      return "硕士及以上";
  }
};

//除了time属性，其他能Number的都Number
export const toNumberObject = (object: { [key: string]: unknown }) => {
  const result = { ...object }; //深度克隆，不改变元对象
  Object.keys(result).forEach((key) => {
    if (
      !key.toLowerCase().includes("time") &&
      !key.toLowerCase().includes("remark")
    ) {
      if (!isNaN(Number(result[key]))) {
        result[key] = Number(result[key]);
      }
    }
  });
  return result;
};

//获取结算类型
export const getBillType = (bill: number) => {
  return bill === 0 ? `元/小时` : bill === 1 ? `元/天` : `元/次`;
};

export interface MyColumns {
  [index: string]: any;
  title: string;
  auth: string;
}

//页面当中自定义列表的参数配置
export interface SelectColumns {
  name: string;
  disable?: boolean;
  checked?: boolean;
  auth?: string;
}
//根据权限码返回columns

export const useGetColumns = () => {
  const { hideAuth } = useAuthButton();

  //获取列表columns项
  const getColumns = (columns: MyColumns[]) => {
    const filterColumns = columns.filter(
      (item) => !item.auth || (item.auth && !hideAuth(item.auth))
    );
    return filterColumns;
  };

  //获取可以选择的columns项
  const getSelectColumns = (selectColumns: SelectColumns[]) => {
    const filterSelectColumns = selectColumns.reduce<SelectColumns[]>(
      (pre, cur) => {
        if (!cur.auth || (cur.auth && !hideAuth(cur.auth))) {
          pre.push(cur);
          return pre;
        }
        return pre;
      },
      []
    );
    return filterSelectColumns;
  };
  return { getColumns, getSelectColumns };
};

//获取本地存储的页数
export const getPage = () => {
  const page = window.localStorage.getItem(CURRENT_PAGE);
  if (page) {
    return Number(page);
  }
  return 1;
};

//获取本地存储的最大数量
export const getLimit = () => {
  const localLimit = window.localStorage.getItem(CURRENT_LIMIT);
  if (localLimit) {
    return Number(localLimit);
  }
  return 10;
};

export const getParams = (obj: object) => {
  const currentParams = window.localStorage.getItem(CURRENT_PARAMS);
  if (currentParams) {
    return JSON.parse(currentParams);
  }
  return obj;
};

//将当前params存储在本地
export const storageObj = (obj: object) => {
  window.localStorage.setItem(CURRENT_PARAMS, JSON.stringify(obj));
};

//获取本地params值
export const getLocalValue = (searchIndex: string) => {
  const currentParams = window.localStorage.getItem(CURRENT_PARAMS);

  if (currentParams) {
    if (String(JSON.parse(currentParams)[searchIndex]).trim()) {
      return JSON.parse(currentParams)[searchIndex];
    }
    return undefined;
  }
  return undefined;
};

//清除本地params状态值
export const deletLocalParams = () => {
  window.localStorage.removeItem(CURRENT_PAGE);
  window.localStorage.removeItem(CURRENT_LIMIT);
  window.localStorage.removeItem(CURRENT_PARAMS);
};

//当自定义表头的内容进行改变时将内容保存在本地
export const handleCheckBoxChange = (
  e: CheckboxChangeEvent,
  value: string, //选中的自定义列表名称
  setColumns: React.Dispatch<React.SetStateAction<MyColumns[]>>,
  columns: MyColumns[], //当前表格的列
  allColumns: MyColumns[], //所有表格的列
  type: string //当前保存的是哪个列表
) => {
  const result = [...columns];
  if (e.target.checked) {
    const lineNumber = result.length - 1;
    const res = filterArray(allColumns, [value]);
    result.splice(lineNumber, 0, res[0] as any);
    setColumns(result);
  } else {
    const res = result.filter((item) => item.title !== value);
    setColumns(res);
  }
};

//获取自定义表头的数据
export const useColumns = (key: string, selectColumns: SelectColumns[]) => {
  const { getSelectColumns } = useGetColumns();
  const getDiyColumns = () => {
    const res = window.localStorage.getItem(key);
    if (res) {
      return JSON.parse(res);
    }
    return getSelectColumns(selectColumns);
  };
  const getColumns = (allColumns: MyColumns[]) => {
    const selectedColums = getDiyColumns() as SelectColumns[];
    const realSelect = selectedColums.reduce<string[]>((pre, cur) => {
      if (cur.checked) {
        pre.push(cur.name);
        return pre;
      }
      return pre;
    }, []);
    realSelect.push("操作");
    const renderColumns = initColumns(realSelect, allColumns);
    return renderColumns;
  };
  return { getDiyColumns, getColumns };
};

//设置当前页数
export const handleSetListPage = (page: number, pageSize: number) => {
  window.localStorage.setItem(CURRENT_PAGE, String(page));
  window.localStorage.setItem(CURRENT_LIMIT, String(pageSize));
};

export const getCurrentTaskStatus = (
  taskStatus: string | number,
  applyStatus: string | number
) => {
  let numberTaskStatus = Number(taskStatus);
  let numberApplyStatus = Number(applyStatus);

  if (numberTaskStatus === 4 || numberTaskStatus === 5) {
    return taskStatusList.find((item) => item.id === numberTaskStatus)?.name;
  }
  if (numberApplyStatus === 1 && numberTaskStatus === 3) {
    return "报名中/进行中";
  }
  if (numberApplyStatus === 2 && numberTaskStatus === 3) {
    return "报名截止/进行中";
  }
  if (numberApplyStatus === 1) {
    return "报名中";
  }
  if (numberApplyStatus === 2) {
    return "报名截止";
  }
  return taskStatusList.find((item) => item.id === numberTaskStatus)?.name;
};
