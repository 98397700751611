import styled from "@emotion/styled";
import { message, Pagination } from "antd";
import { useEffect, useState } from "react";
import { deletLocalParams, handleSetListPage } from "utils";

export const CURRENT_PAGE = "curretPage";
export const CURRENT_LIMIT = "curretLimit";
export const CURRENT_PARAMS = "currentParams";

export const MPagination = ({
  handleOnchange,
  count,
  page,
  limit,
  setPage,
  setLimit,
  marginTop = 4,
}: {
  handleOnchange: (page: number, pageSize: number) => void;
  count: number;
  page: number;
  limit: number;
  setPage: (value: number) => void;
  setLimit: (value: number) => void;
  marginTop?: number;
}) => {
  useEffect(() => {
    return () => {
      deletLocalParams();
    };
  }, []);

  return (
    <PaginationWrapper marginTop={marginTop}>
      <Pagination
        current={page}
        total={count}
        showQuickJumper
        showSizeChanger
        pageSize={limit}
        onChange={(page, pageSize) => {
          if (page && pageSize) {
            setPage(page);
            setLimit(pageSize);
            handleOnchange(page, pageSize);
            handleSetListPage(page, pageSize);
          }
        }}
      />
      <TotalNumber>共{count}条</TotalNumber>
    </PaginationWrapper>
  );
};

const PaginationWrapper = styled.div<{ marginTop: number }>`
  display: flex;
  margin-top: ${(props) => props.marginTop + "rem"};
  justify-content: center;
  align-items: center;
  padding-bottom: 0.1rem;
`;
export const TotalNumber = styled.span`
  margin-left: 1.6rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #828282;
  margin-top: 0.5rem;
`;
