import "App.css";
import AuthenticatedApp from "authenticatedApp";
import { useAuth } from "context/auth-context";
import UnAuthenticatedApp from "unAuthenticatedApp";
import zhCN from "antd/lib/locale/zh_CN";
import { ConfigProvider } from "antd";
import ErrorBoundary from "component/errorBoundary";
import { FullPageErrorFallback } from "component/lib";

function App() {
  const { token } = useAuth();
  return (
    <ConfigProvider locale={zhCN}>
      <div className="App">
        <ErrorBoundary fallBackRender={FullPageErrorFallback}>
          {token ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
        </ErrorBoundary>
      </div>
    </ConfigProvider>
  );
}

export default App;
