import LoginScreen from "./login";
import styled from "@emotion/styled";
import loginBg from "assets/img/loginBg.png";
import Logo from "assets/img/logo.svg";
import { useState } from "react";
export default function UnAuthenticatedApp() {
  const [error, setError] = useState<Error | null>(null);
  return (
    <Container>
      <ContentWrapper>
        <LeftBg src={loginBg}></LeftBg>
        <RightContent>
          <Header>
            <img src={Logo} alt="logo" />
            <HeaderTitle>i找人后台系统</HeaderTitle>
          </Header>
          <Content>
            <ContentTitle>登录</ContentTitle>
            <FormWrapper>
              <LoginScreen error={error} onError={setError} />
            </FormWrapper>
          </Content>
        </RightContent>
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;

  box-shadow: 0 0.4rem 1.6rem rgba(121, 146, 208, 0.6);
  border-radius: 1.6rem;
`;
const LeftBg = styled.img`
  width: 39.6rem;
  height: 56rem;
`;
const RightContent = styled.div`
  width: 48.4rem;
  height: 56rem;
  background: #ffffff;
  border-radius: 0 1.6rem 1.6rem 0;
  padding-top: 6.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3.4rem;
  color: #4076f5;
  margin-left: 1.8rem;
`;

const Content = styled.div`
  margin-top: 6rem;
`;
const ContentTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #333333;
`;

const FormWrapper = styled.div`
  width: 32rem;
  margin-top: 2.4rem;
`;
