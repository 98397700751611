import React, { ReactNode, useState, useEffect } from "react";
import * as Auth from "auth-provider";
import { AuthForm } from "auth-provider";
import { resetRoutes } from "utils";
import { http } from "utils/http";
import { authorityType } from "utils/type";
import { errorType } from "utils/useAsync";

//创建一个传递各个组件的Context
const AuthContext = React.createContext<
  //context上的属性类型定义
  | {
      login: (form: AuthForm) => Promise<void>;
      user: Auth.Users | null;
      logout: () => Promise<void>;
      token: string | null;
      authority: authorityType[] | null;
      authorityLoading: boolean;
      authorityError: errorType | undefined;
      isCompany: boolean | undefined;
      isPlatform: boolean | undefined;
      enterpriseId: number | undefined;
      enterprise: string | undefined;
    }
  | undefined
>(undefined);

AuthContext.displayName = "AuthContext";

const bootstrapUser = async () => {
  let user = null;
  const token = Auth.getToken();

  if (token) {
    const { data } = await http("api-user/user/current", { token });
    user = data;
  }
  return user;
};

const getAuthority = async (setAuthorityLoading: (value: boolean) => void) => {
  const token = Auth.getToken();
  if (token) {
    setAuthorityLoading(true);
    const { data: authority, error } = await http(`api-user/menus/current`, {
      token,
    });
    setAuthorityLoading(false);
    return { authority, error };
  }
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  //大概的思路就是登陆成功之后保存返回的user
  const [user, setUser] = useState<Auth.Users | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [authority, setAuthority] = useState<authorityType[] | null>(null);
  const [authorityLoading, setAuthorityLoading] = useState(false);
  const [authorityError, setAuthorityError] = useState<errorType>();
  const [isCompany, setIsCompany] = useState<boolean | undefined>(undefined);
  const [isPlatform, setIsPlatform] = useState<boolean>();
  const [enterpriseId, setEnterpriseId] = useState<number | undefined>();
  const [enterprise, setEnterprise] = useState<string | undefined>();
  const login = (form: AuthForm) => Auth.login(form).then(setUser);

  const logout = () =>
    Auth.logout().then(() => {
      resetRoutes();
      setUser(null);
      setToken(null);
    });

  const usertoken = Auth.getToken();
  useEffect(() => {
    setToken(usertoken);
    bootstrapUser().then((user) => {
      if (user) {
        const { roles, enterpriseId, enterprise } = user;
        if (roles) {
          switch (roles[0].roleType) {
            case 0:
              setIsCompany(true);
              setEnterpriseId(enterpriseId);
              setEnterprise(enterprise);
              break;
            case 1:
              setIsPlatform(true);
              break;
          }
        }
      }
      setUser(user);
    });
    getAuthority(setAuthorityLoading).then((authority) => {
      setAuthority(authority?.authority);
      setAuthorityError(authority?.error);
    });
  }, [usertoken]);

  return (
    <AuthContext.Provider
      children={children}
      value={{
        user,
        login,
        logout,
        token,
        authority,
        authorityLoading,
        authorityError,
        isCompany,
        isPlatform,
        enterpriseId,
        enterprise,
      }}
    ></AuthContext.Provider>
  );
};

//用useAuth将user传递下去
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth请在AuthProvider中使用");
  }
  return context;
};
