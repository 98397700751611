import { Component } from "react";

//错误边界必须用component组件来实现

type FallBackRender = (props: { error: Error | null }) => React.ReactElement;

export default class ErrorBoundary extends Component<
  React.PropsWithChildren<{ fallBackRender: FallBackRender }>,
  { error: Error | null }
> {
  state = { error: null };
  static getDerivedStateFromError(error: Error) {
    return { error };
  }
  render() {
    const { error } = this.state;
    const { children, fallBackRender } = this.props;
    if (error) {
      return fallBackRender({ error });
    }
    return children;
  }
}
