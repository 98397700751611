import { useState } from "react";
import styled from "@emotion/styled";
import { Dropdown, Menu } from "antd";
import { ReactComponent as Arrows } from "assets/img/arrows.svg";
import { ReactComponent as Hexagon } from "assets/img/hexagon.svg";
import { ReactComponent as Lock } from "assets/img/lock.svg";
import { ReactComponent as PowerOff } from "assets/img/powerOff.svg";
import { useAuth } from "context/auth-context";
import { useNavigate } from "react-router-dom";

interface IUserOptionsMenu {
  key: string;
  title: string;
  icon: React.FunctionComponent;
  action?: (info?: any) => void;
}

export default function UserOptions() {
  const { logout } = useAuth();
  const [visiable, trigger] = useState(false);
  const history = useNavigate();

  const handleVisibleChange = () => {
    trigger(!visiable);
  };

  const options: Array<IUserOptionsMenu> = [
    {
      key: "setting",
      title: "账号设置",
      icon: Hexagon,
      action: () => {
        history("/setting");
      },
    },
    {
      key: "mod-pwd",
      title: "修改密码",
      icon: Lock,
      action: () => {
        history("/password");
      },
    },
    { key: "logout", title: "退出登录", icon: PowerOff, action: logout },
  ];

  const menu = (
    <MenuWrapper>
      {options.map((option) => (
        <Menu.Item
          icon={<option.icon />}
          key={option.key}
          onClick={option.action}
        >
          {option.title}
        </Menu.Item>
      ))}
    </MenuWrapper>
  );

  return (
    <Dropdown
      trigger={["click"]}
      placement="bottomRight"
      overlay={menu}
      onVisibleChange={handleVisibleChange}
    >
      <SwitchArrows reversal={visiable}>
        <Arrows />
      </SwitchArrows>
    </Dropdown>
  );
}

export const SwitchArrows = styled.div<{
  reversal: boolean;
}>`
  transform: rotate(${(props) => (props.reversal ? 180 : 0)}deg);
  transform-origin: center center;
  cursor: pointer;
  height: 2.4rem;
`;

const MenuWrapper = styled(Menu)`
  width: 16rem;
  border-radius: 0.8rem;
  margin-top: 1.6rem;
  padding: 0.9rem 0;
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    padding: 1.3rem 2.5rem;
    span {
      color: #000;
      margin-left: 1rem;
    }
  }
`;

export type { IUserOptionsMenu };
