export const educationList = [
  { id: 1, name: "高中" },
  { id: 2, name: "专科" },
  { id: 3, name: "本科" },
  { id: 4, name: "硕士及以上" },
];

export const genderList = [
  { id: 0, name: "男" },
  { id: 1, name: "女" },
];

export const imgUrl = `${process.env.REACT_APP_API_URL}/api-user/picture/show?fileName=`;

//任务状态
export const taskStatusList = [
  {
    id: 0,
    name: "待发布",
  },
  {
    id: 1,
    name: "报名中",
  },

  {
    id: 3,
    name: "进行中",
  },
  {
    id: 4,
    name: "已完成",
  },
  {
    id: 5,
    name: "已关闭",
  },
  {
    id: 6,
    name: "报名截止",
  },
];

//结算当中的任务状态
export const costTaskStatusList = [
  {
    id: 1,
    name: "报名中",
  },
  {
    id: 2,
    name: "报名截止",
  },
  {
    id: 3,
    name: "进行中",
  },
  {
    id: 4,
    name: "已完成",
  },
  {
    id: 5,
    name: "已关闭",
  },
];

//薪酬类型
export const payTypeList = [
  {
    id: 0,
    name: "时薪",
  },
  {
    id: 1,
    name: "日薪",
  },
  {
    id: 2,
    name: "按任务结算",
  },
  {
    id: 3,
    name: "灵活配置",
  },
];
// 短信类型
export const MessageType = [
  {
    id: 0,
    name: "报名审核",
  },
  {
    id: 1,
    name: "任务流程",
  },
  {
    id: 2,
    name: "结算相关",
  },
  {
    id: 3,
    name: "其他",
  },
];
// 短信状态
export const MessageStatus = [
  {
    id: 0,
    name: "发送中",
  },
  {
    id: 1,
    name: "已发送",
  },
  {
    id: 2,
    name: "发送失败",
  },
];
// 短信发送状态
export const MessageSendType = [
  {
    id: 0,
    name: "自动发送",
  },
  {
    id: 1,
    name: "手动发送",
  },
];
export interface enrolDetail {
  accountBank: string;
  enclosure: string;
  identityNumber: string;
  cardNumber: string;
  cardholder: string;
  identifier: string;
  id: number | string;
  name: string;
  account: string;
  email: string;
  gender: number;
  age: string;
  height: string;
  weight: string;
  school: string;
  role: string;
  createTime: string;
  avatar: string;
  score: string;
  status: string;
  education: number | string;
  tags: string[];
  password: string;
  address: string;
  username: string;
  enterprise: string;
  birthday: string;
  hometown: string;
  shoe: number | string;
  college: string;
  specialty: string;
  certificate: string;
  graduationTime: string;
  selfIntroduction: string; //自我介绍
  remarks: string; //备注
  idCardImageA: string;
  idCardImageB: string;
  taskcount: string;
  punctuality: string;
  healthCodeImg: string;
  studentCardImg: string;
  registrationPicture: string[];
  qualification: { id: number; path: string }[];
  applyStatus: number | null;
}
// 结算状态
export const settleStatusType = [
  {
    id: 0,
    name: "待发送",
  },
  {
    id: 1,
    name: "待审核",
  },
  {
    id: 2,
    name: "审核通过",
  },
  {
    id: 3,
    name: "审核驳回",
  },
];

export const enrolmentStatus = [
  {
    id: 0,
    name: "待审核",
  },
  {
    id: 1,
    name: "已通过",
  },
  {
    id: 2,
    name: "已驳回",
  },
  {
    id: 3,
    name: "已拒绝",
  },
  {
    id: 4,
    name: "正常取消",
  },
  {
    id: 5,
    name: "违约取消",
  },
];
// 报销状态
//待审核、已通过、已拒绝、已驳回
export const remiburseStatusType = [
  {
    id: 0,
    name: "待审核",
  },
  {
    id: 1,
    name: "已通过",
  },
  {
    id: 3,
    name: "已拒绝",
  },
  {
    id: 2,
    name: "已驳回",
  },
];
// 任务状态转换
export function convertTaskStatus(name: string) {
  const taskStatus = enrolmentStatus.find((item) => item.name === name);
  return taskStatus && taskStatus?.id === 5 ? 4 : taskStatus?.id;
}

//任务列表-搜索字段
export interface taskSearchType {
  keyword: string;
  tags: string[];
  enterprise: number | undefined;
  status: string;
  startTime: string;
  endTime: string;
  startPay: string;
  endPay: string;
  payType: string;
}

//任务报名列表-搜索字段
export interface enrolSearchType {
  keyword: string;
}

//任务报名管理-拒绝/通过/取消报名modal字段
export interface operateModalVisibleType {
  visible: boolean;
  data: {
    accountId?: number;
    [propName: string]: any;
  };
}

//报名管理-批量审核字段
export interface batchAuditDataType {
  visible: boolean;
  confirm: boolean;
  data: Array<any>;
}

//角色列表过滤器分类列表
export const roleSortList = [
  {
    id: 0,
    name: "企业",
  },
  {
    id: 1,
    name: "平台",
  },
  {
    id: 2,
    name: "志愿者",
  },
];

//角色列表过滤器状态列表
export const roleStatus = [
  {
    id: 0,
    name: "启用",
  },
  {
    id: 1,
    name: "关闭",
  },
];

//权限设置字段
export interface authorityType {
  path: string;
  name: string;
  permision: {};
  id: number;
  subMenus: authorityType[];
  parentId?: number;
  url: string;
}

//权限设置列表字段
export interface authorityList {
  miniprogram: any;
  platform: authorityType[];
  selectIds: number[];
}

//按钮级别权限码
export const authorityCode = [
  "enterprise-btn-query", //企业管理关键字搜索
  "enterprise-btn-add", //企业管理新建企业按钮
  "enterprise-btn-export", //企业管理导出按钮
  "enterprise-complaint-btn-delete", //企业管理投诉记录删除按钮
  "task-btn-handleComplaint", //任务管理处理投诉按钮
  "enterprise-evaluation-btn-delete", //企业管理评价记录删除按钮
  "enterprise-see-evaluation", //企业详情查看评价记录
  "enterprise-see-complaint", //企业详情查看投诉记录
  "task-btn-release", //任务管理发布任务按钮
  "task-btn-export", //任务管理导出按钮
  "task-see-pay", //任务详情查看薪酬信息默认薪酬
  "task-edit-pay", //任务详情编辑薪酬信息默认薪酬
  "task-see-overTimePay", //任务详情查看加班薪酬成本价
  "task-edit-overTimePay", //任务详情编辑加班薪酬成本价
  "task-btn-complete", //任务详情将任务标记为完成按钮
  "task-enrol-query-tags", //任务报名信息过滤器标签
  "task-enrol-query-score", //任务报名信息过滤器综合评分
  "task-created-pay", //任务管理创建任务薪酬成本价
  "task-query-enterprise", //任务列表过滤器企业
  "task-enrol-see-operation", //任务报名信息查看操作记录按钮
  "task-enrol-export", //任务报名信息导出按钮
  "task-btn-personalAdd", //任务人员信息添加成员
  "task-btn-personalExport", //任务人员信息导出
  "task-btn-attendanceExport", //任务管理考勤信息导出
  "task-btn-attendanceAddCard", //任务管理考勤信息补卡
  "task-btn-costExport", //任务管理结算记录导出
  "task-btn-complaintAddCompany", //任务管理投诉记录新增企业投诉记录按钮
  "task-btn-complaintAddVolunteer", //任务管理投诉记录新增志愿者投诉记录按钮
  "task-btn-complaintHandleCompany", //任务管理投诉记录处理企业投诉记录按钮
  "task-btn-complaintHandleVolunteer", //任务管理投诉记录处理志愿者投诉记录按钮
  "task-btn-complaintDeleteCompany", //任务管理投诉记录删除企业投诉记录按钮
  "task-btn-complaintDeleteVolunteer", //任务管理投诉记录删除志愿者投诉记录按钮
  "task-btn-evaluateAddCompany", //任务管理评价记录新增企业评价记录按钮
  "task-btn-evaluateAddVolunteer", //任务管理评价记录新增志愿者评价记录按钮
  "task-btn-evaluateDeleteCompany", //任务管理评价记录删除企业评价记录按钮
  "task-btn-evaluateDeleteVolunteer", //任务管理评价记录删除志愿者评价记录按钮
  "task-columns-salary", //任务管理列表表格薪酬字段
  "cost-query-enterprise", //费用管理列表关键字搜索企业字段
  "cost-query-totalCost", //费用管理列表关键字搜索总金额
  "cost-btn-export", //费用管理导出
  "cost-btn-send", //费用管理发送
  "cost-btn-pass", //费用管理通过
  "cost-btn-reject", //费用管理驳回
  "cost-btn-Printing", //结算信息成本价打印
  "cost-settlement-btn-export", //结算信息成本价结算导出
  "cost-settlement-edit-tax", //结算信息合同价编辑税率
  "cost-settlement-edit-remark", //结算信息合同价编辑税率
  "cost-settlement-btn-pass", //结算信息合同价通过
  "cost-settlement-btn-reject", //结算信息合同价驳回
  "cost-settlement-btn-send", //结算信息合同价发送账单
  "cost-detail-query", //账单详情关键字搜索
  "cost-detail-add", //账单详情新增账单
  "cost-detail-edit-bill", //账单详情编辑个人账单
  "cost-detail-batchEdit-bill", //账单详情编辑个人账单
  "cost-detail-btn-export", //账单详情导出
  "cost-detail-auditRecord", //账单详情审核记录
  "cost-reimbursement-btn-add", //账单报销记录新增报销
  "cost-reimbursement-btn-edit", //账单报销信息编辑报销
  "cost-reimbursement-btn-batchEdit", //账单报销信息批量编辑报销
  "cost-reimbursement-btn-pass", //账单报销信息通过
  "cost-reimbursement-btn-reject", //账单报销信息驳回
  "cost-reimbursement-btn-refuse", //账单报销信息拒绝
  "cost-reimbursement-btn-export", //账单报销信息导出
  "cost-reimbursement-btn-record", //账单报销信息审核记录
  "cost-labour-query", //劳务费用关键字搜索
  "cost-labour-btn-edit", //劳务费用编辑
  "cost-labour-btn-export", //劳务费用导出
  "cost-other-btn-add", //新增其他费用
  "cost-other-btn-edit", //编辑其他费用
  "cost-other-btn-export", //其他费用导出
  "cost-operation-record", //结算详情操作记录
  "cost-columns-settlement-cost-price", //结算首页columnc字段结算成本价
  "cost-columns-salary-cost-price", //结算首页columnc字段薪酬成本价
  "cost-columns-reimbursement-cost-price", //结算首页columnc字段报销成本价
  "cost-columns-other-cost-price", //结算首页columnc字段其他成本价
  "cost-columns-meal-cost-price", //结算首页columnc字段餐补成本价
  "cost-columns-tax-cost-price", //结算首页columnc字段成本税费价
];

//上传报销凭证
export const isUpload = [
  {
    name: "是",
    id: 1,
  },
  {
    name: "否",
    id: 2,
  },
];
// 结算信息 审核状态
export const AuditStatusType = [
  {
    id: 0,
    name: "待审核",
  },
  {
    id: 1,
    name: "已通过",
  },
  {
    id: 2,
    name: "已拒绝",
  },
  {
    id: 3,
    name: "已驳回",
  },
];

export interface imageType {
  id: number;
  path: string;
}

//帐号状态
export const accountStatus = [
  {
    id: 0,
    name: "未认证",
  },
  {
    id: 1,
    name: "待审核",
  },
  {
    id: 2,
    name: "已驳回",
  },
  {
    id: 3,
    name: "已认证",
  },
];

//报名状态
export const enrollStatus = [
  {
    id: 0,
    name: "已报名",
  },
  {
    id: 1,
    name: "驳回",
  },
  {
    id: 2,
    name: "拒绝",
  },
  {
    id: 3,
    name: "已通过",
  },
  {
    id: 4,
    name: "正常取消",
  },
  {
    id: 5,
    name: "违约取消",
  },
];

//结算列表首页搜索过滤框当中的结算状态
export const costStatus = [
  {
    id: 0,
    name: "待发送",
  },
  {
    id: 1,
    name: "待审核",
  },
  {
    id: 2,
    name: "已通过",
  },
  {
    id: 3,
    name: "已驳回",
  },
];

export const ConpanyCostStatus = [
  {
    id: 1,
    name: "待审核",
  },
  {
    id: 2,
    name: "已通过",
  },
  {
    id: 3,
    name: "已驳回",
  },
];

export const allAttanceType = [
  { name: "正常", id: 0 },
  { name: "迟到", id: 1 },
  { name: "早退", id: 2 },
  { name: "缺卡", id: 3 },
  { name: "迟到且早退", id: 4 },
];

export const signInType = [
  { name: "正常", id: 0 },
  { name: "迟到", id: 1 },
  { name: "缺卡", id: 3 },
];
export const signOutType = [
  { name: "正常", id: 0 },
  { name: "早退", id: 2 },
  { name: "缺卡", id: 3 },
];
